import React from 'react'
import TopNavigation from '../Components/Widgets/TopNavigation'
import axios from 'axios';
import * as CONSTANTS from "./../CONSTANTS";
import { toast } from 'react-toastify';

function Competitions() {
  return (
    <div>
      <div className="top-navbar">
            <TopNavigation title={"Competitions"} />
        </div>
        <div className="layer-block">
            <div className="layer-content">
            Competitions
            </div>
        </div>
    </div>
  )
}

export default Competitions