import React from 'react'
import TopNavigation from '../Components/Widgets/TopNavigation'
import { useSelector } from 'react-redux';
import { FaUser } from 'react-icons/fa';

function ProfileScreen() {

    const {user}                                                        = useSelector((state) => state.auth);
  return (
    <div>
        <div className="top-navbar">
            <TopNavigation title={"Profile"} />
        </div>
        <div className="layer-block">
           <div className="layer-content">
                    <div className="profile-image-container">
                        <div className="profile-image">
                            {
                                user?.profilePic &&
                                user?.profilePic.length > 3 
                                ? ( <div className="profile-user-image">
                                        <img src={user?.profilePic} className="fit-image" />    
                                    </div>) 
                                : (
                                    <div className="profile-user-icon">
                                        <FaUser />
                                    </div>
                                )
                            }
                            


                        </div>
                    </div>
                    <div className="profile-content">
                        <table className="table table-striped">
                            <tbody>
                                <tr>
                                    <td>
                                        {user.name}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {user.surname}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {user.phonenumber}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {user.email}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
           </div>
        </div>
    </div>
  )
}

export default ProfileScreen