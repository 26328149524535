import React, { useEffect, useRef, useState } from 'react';
import './authstyle.css'
import HeaderLogo from './../assets/logo.png';
import * as CONSTANTS from "../CONSTANTS";
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { register, reset } from '../reduxAuth/authSlice';
import Spinner from '../Components/Others/Spinner';

function AuthRegisterScreen() {
  const navigate                                       = useNavigate();
  const dispatch                                       = useDispatch();

  const {user, isLoading, isError, isSuccess, message} = useSelector((state) => state.auth);

  const nameRef                                           = useRef();
  const surnameRef                                        = useRef();
  const phoneNumberRef                                    = useRef();
  const passwordRef                                       = useRef();
  const idnumberRef                                       = useRef();
  const emailRef                                          = useRef();
  const empnumberRef                                      = useRef();

  useEffect(() => {
      if(isError){
          toast.error(message)
      }
    
      if(isSuccess || user){
        toast.success("Thank you for registering.")
         setTimeout(() => {
          navigate('/login');
         }, 4000);
          
      }

        dispatch(reset());
  },[user, isError, isSuccess, message, navigate, dispatch])


  const handleRegister = async (e) => {
    e.preventDefault();

      let checkTest = isValidPhoneNumber(phoneNumberRef.current.value);
      if(checkTest){
          const userData = {
              "phonenumber": phoneNumberRef.current.value,
              "password": passwordRef.current.value,
              "idnumber": idnumberRef.current.value,
              "name": nameRef.current.value,
              "surname": surnameRef.current.value,
              "email": emailRef.current.value,
              "empnumber": empnumberRef.current.value,
              "profilePic":""
            }        
            
            dispatch(register(userData));
      }else {
          toast.error("Phone Number not valid");
      }
  }

  function isValidPhoneNumber(cell){
    if (!cell) {
      return false; // Handle empty input
    }
  
    // Remove any non-digit characters (e.g., spaces, hyphens, parentheses)
    const cleanedPhoneNumber = cell.replace(/\D/g, '');
    // Check if the cleaned number is exactly 10 digits
    return /^\d{10}$/.test(cleanedPhoneNumber);
  }

  if (isLoading) {
      return  <Spinner />
  }

  return (
    <div className="logo-base flexlog">
      <div className="log-start">
          
          <div className="main-login-data">
            <div className="reg-header ">
                <img src={HeaderLogo} className="logo-one" alt="The Local Choice" />         
            </div>
              <div className="form-card ">
                  <div className="frm-log-area">
                      <h4 className="title-login text-center">Register</h4>
                          <form encType="multipart/form-data">
                              <div className="form-group frg">
                                  <input 
                                    type="text" className="form-control ct-content matetrial-input wide100" 
                                    ref={phoneNumberRef} 
                                    maxLength={10} 
                                    placeholder="Enter Phone Number*" required/>
                              </div>
                              <div className="form-group frg">
                                  <input type="password" 
                                    className="form-control ct-content matetrial-input wide100"  
                                    ref={passwordRef} 
                                    placeholder="Enter Password*" required/>
                              </div>
                              <div className="form-group frg">
                                  <input type="text" 
                                    className="form-control ct-content matetrial-input" 
                                    ref={nameRef} 
                                    placeholder="Enter Name*" required/>
                              </div>
                              <div className="form-group frg">
                                  <input 
                                      type="text" 
                                      className="form-control ct-content matetrial-input"  
                                      ref={surnameRef} 
                                      placeholder="Enter Surname*" required/>
                              </div>
                              <div className="form-group frg">
                                  <input 
                                      type="text" 
                                      className="form-control ct-content matetrial-input" 
                                      ref={idnumberRef} placeholder="Enter ID Number" required/>
                              </div>
                              <div className="form-group frg">
                                  <input type="email" 
                                    className="form-control ct-content matetrial-input"  
                                    ref={emailRef} placeholder="Enter Email Address" />
                              </div>

                              <div className="form-group frg">
                                  <input type="text" 
                                    className="form-control ct-content matetrial-input"  
                                    ref={empnumberRef} placeholder="Enter Employee Number" />
                              </div>
                              <div className="form-group mgtop20">
                                  <button 
                                      className="btn btn-mevent btn-full" 
                                      onClick={handleRegister} 
                                      disabled={isLoading}>Register
                                  </button>
                              </div>
                              <div className=" frg">
                              </div>
                          </form>
                                                    
                          <p className="mgtop20 space-flex txts12">
                              <Link to="/forgot-password"  className="link-log-text">Forgot Password?</Link>
                              <Link to="/login"  className="link-log-text">Login?</Link>
                          </p>
                          <p className="text-center smal-g">
                          { CONSTANTS.VERSION}
                          </p>
                          <div className="dot dot1" ></div>
                          <div className="dot dot2" ></div>
                  </div>
              </div>
          
          </div>
      </div>       
  </div>
  )
}

export default AuthRegisterScreen