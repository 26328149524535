import React, { useEffect, useRef, useState } from 'react'
import TopNavigation from "./../Components/Widgets/TopNavigation";
import { BrowserMultiFormatReader } from "@zxing/browser";
import { toast } from 'react-toastify';
import mevError from './../assets/mevent-error.png';
import mevCheck from './../assets/mevent-check.png';
import Loading from '../Components/Others/Loading';
import { useSelector } from 'react-redux';
import axios from 'axios';
import * as CONSTANTS from "./../CONSTANTS";

function QRCodeScan() {

  const {user}                                                        = useSelector((state) => state.auth);
  const [scanBtnShow, setScanBtnShow]                  = useState(true);
  const [scanning, setScanning]                        = useState(true);
  const [loading, setLoading]                          = useState(false);
  const [responseData, setResponseData]                = useState(null);

  const videoRef                                       = useRef(null);

  const startScan = async () => {
   
    setScanBtnShow(false)
    setScanning(true);
    setResponseData(null);

    
    const codeReader = new BrowserMultiFormatReader();
    
    try {
      const videoElement = videoRef.current;
      console.log(videoElement);
      const result = await codeReader.decodeOnceFromVideoDevice(undefined, videoElement);
      console.log(result);
      console.log("Start Scan");
      console.log(result.text);
      
      handleScannedData(result.text);
    } catch (err) {
      console.error("QR scanning error:", err);
      toast.error("QR scanning failed. Try again.");
    }
    
  };

  const handleScannedData = async (data) => {
    console.log("Data");
    console.log(data);

    if (!/^\w{6}$/.test(data)) {
      toast.error("Invalid QR code!");
      setResponseData({ status: "error" });
      return;
    }

    setScanning(false);
    setLoading(true);

    try {
      
      const voting = {
        "userid" : user._id,
        "votingCode" : data
      }

      const response = await axios.post(CONSTANTS.API_URL +"voting/creation", voting, {
              headers: {
                  token: "Bearer "+ user.accessToken
              }
          });

          console.log(response);
      //setResponseData({ status: "success", data: result });
      setLoading(false);
    } catch (error) {
      toast.error("Server error!");
      setResponseData({ status: "error" });
    } finally {
      setLoading(false);
    }
  };

  const stopScan = async () => {
    setScanBtnShow(true)
    setScanning(false);
    setResponseData(null);
  }

  return (
    <div>
        <div className="top-navbar">
            <TopNavigation title={"QR Scanning"} />
        </div>
        <div className="layer-block">
           <div className="layer-content">
               
              <div className="pace-scan">
                  {
                    scanBtnShow ? (
                        <button className="btn btn-mevent" onClick={startScan}>
                          SCAN
                        </button>
                      ) : 
                        (
                          <button className="btn btn-mevent" onClick={stopScan}>
                            STOP SCANNING
                          </button>
                        )
                  }
              </div>

              {
                scanning && (
                  <div className="scanning-area">
                    <video ref={videoRef} style={{ width: "100%" }} />
                  </div>
                )
              }

              {loading && <Loading />}

               {
                responseData && (
                    <div className="response">
                      {responseData.status === "success" ? (
                        <div className="respone-area">
                           <div className="title-response"></div>
                          <img src={mevCheck} alt="Success" />
                        </div>
                      ) : (
                        <div className="respone-area">
                          <div className="title-response"></div>
                          <img src={mevError} alt="Error" />
                        </div>
                      )}
                    </div>
                  )
                }

           </div>
        </div>
    </div>
  )
}
//Create a qr code scanner
//If a six character string is decoded from the qr code
//Then hide the scanner
//show the <div class="loading"></div> while you make post request. 
//Once the data returns hide the loading div and show the response div with complete image
//if the qr code returns anything except for a six digit number then toast invalid code and
//show the response div with error image

export default QRCodeScan