import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import * as CONSTANTS from "./../CONSTANTS";
import { FaUser } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Spinner from '../Components/Others/Spinner';
import { setUser } from "../reduxAuth/authSlice"; 
import EventSingle from '../Components/Widgets/EventSingle';
import EventMultiple from '../Components/Widgets/EventMultiple';
import { Link } from 'react-router-dom';

function HomeScreen() {

  const dispatch                                                      = useDispatch();

  const {user}                                                        = useSelector((state) => state.auth);

  const [isLoading, setIsLoading]                                     = useState(false);
  const [eventCode, setEventCode]                                     = useState("");

  const [eventList, setEventList]                                     = useState([]);

  useEffect(() => {
    getEventList();
  },[user])

  const getEventList = async () => {
      if(user.events.length > 0){
        //////////////////////
          try
          {
            
            const eventObject = {
              "eventCodes" : user.events
            }

          const eventData = await axios.put(CONSTANTS.API_URL +"events/list/specific", eventObject, {
                  headers: {
                      token: "Bearer "+ user.accessToken
                  }
              });

              console.log(eventData);
              setEventList(eventData.data);
        }catch(error){
            console.log(error);
        }
        //////////////////////
      }
  }

  const activateEventAccount = async (e) => {
    e.preventDefault();

    if(eventCode.length === 5){
      setIsLoading(true);
      try{

        const userActivate = {
          "id" : user._id,
          "code" : eventCode 
        }

        const response = await axios.put(CONSTANTS.API_URL +"users/activate", userActivate, {
              headers: {
                  token: "Bearer "+ user.accessToken
              }
          });

        setIsLoading(false);
        
        if (response.status === 200) {
            dispatch(setUser(response.data)); // Update Redux store
            localStorage.setItem(CONSTANTS.SESSION_COOKIE, JSON.stringify(response.data));
            toast.success("User has been updated. Thank you.");
        }else {
          toast.warning("Activation had an issue, please try again.");
        }

      }catch(err){
        console.log(err);
        setIsLoading(false);
        toast.error("Something went wrong, please try again later.");
      }
    }else {
      toast.warning("Event Code Length is incomplete.")
    }
  }

  if (isLoading) {
      return  <Spinner />
  }

  return (
    <div>
        
        <div className="header-row">
          <div className="item-head">
            <h2 className="line-one">Hi  <span className="colorred">{user.name}</span>, </h2>
            <div className="line-two">Welcome to the Mevent App.</div>
          </div>
          <div className="item-head prof-lane">
            {
              user.profilePic.length > 3 ?
              <div className="item-profile">
                <div className="profile-image">

                </div>
              </div>
              :
              <div className="item-profile">
                 <div className="profile-icon">
                    <Link to={"/profile"} className="colorred">
                       <FaUser />
                    </Link>
                 </div>
              </div>
            }
          </div>
        </div>
        <div className="main-area-view"> 
            {
              user.events.length > 0 ?
              <div className="event-show">
                {
                  eventList &&
                  eventList.length > 0 && (
                    <>
                    {
                      user.events.length === 1 ? 
                           <EventSingle  
                              eventContent={eventList[0]}                        
                              user={user}
                             />
                        :
                           <div  className="event-main">
                              <EventMultiple 
                                eventContent={eventList} 
                                user={user}                         
                              />
                           </div>
                    }
                    </>
                  )
                }
              </div> 
              :
              <div className="add-event">
               
                <div className="add-event-base-form">
                <h2 className="add-event-title">Add Event</h2>
                  <form onSubmit={activateEventAccount}>
                    <div className="form-group">
                      <input 
                        type="text" 
                        className="form-control fr-phase-code" 
                        maxLength={5} 
                        onChange={(e)=> setEventCode(e.target.value)}
                        placeholder="Enter Code" />
                    </div>
                    <div className="form-group">
                      <button className="btn btn-mevent" >
                        Activate
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            }
        </div>
    </div>
  )
}

export default HomeScreen