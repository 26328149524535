import React from 'react'
import TopNavigation from "./../Components/Widgets/TopNavigation";
import * as CONSTANTS from "./../CONSTANTS";
import { toast } from 'react-toastify';
import mevLand from './../assets/mevent-input.png';
import mevCheck from './../assets/mevent-check.png'
import mevError from './../assets/mevent-error.png';

function QRCodeInput() {

  const handleCodeVote = async (e) => {
    e.preventDefault();

      try{


      }catch(err){
        console.log(err);
      }
  }

  return (
    <div>
        <div className="top-navbar">
            <TopNavigation title={"QR Code Input"} />
        </div>
        
        <div className="layer-block">
           <div className="layer-content">
              <img src={mevLand} className="image-dispay-v" />

              <div className="land-item-form">
                  <form onSubmit={handleCodeVote}>
                    <div className="form-group">
                       <h3>Enter your Session or Supplier Code</h3>
                       <input 
                        type="text"
                        className="form-control ses-code"
                        maxLength={6}
                        required
                        />
                    </div>
                    <div className="form-control">
                      <button className="btn btn-mevent">Submit</button>
                    </div>
                  </form>
              </div>
           </div>
        </div>
      </div>
  )
}

export default QRCodeInput