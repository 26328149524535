import React, { useRef, useState } from 'react'
import { toast } from 'react-toastify';
import Spinner from '../Components/Others/Spinner';
import { Link } from 'react-router-dom';
import HeaderLogo from './../assets/logo.png';
import * as CONSTANTS from "../CONSTANTS";

function AuthForgotScreen() {

    const [isLoading, setIsLoading]                         = useState(false);
    const phoneNumberRef                                    = useRef();

    const handleForgotPassword = async (e) => {
      e.preventDefault();

       try{

       }catch(error){
        console.log(error);
       }
    }

    function isValidPhoneNumber(cell){
        if (!cell) {
          return false; // Handle empty input
        }
      
        // Remove any non-digit characters (e.g., spaces, hyphens, parentheses)
        const cleanedPhoneNumber = cell.replace(/\D/g, '');
        // Check if the cleaned number is exactly 10 digits
        return /^\d{10}$/.test(cleanedPhoneNumber);
    }
    
    if (isLoading) {
          return  <Spinner />
    }

    const handleForgot = async (e) => {
          e.preventDefault();
      
            let checkTest = isValidPhoneNumber(phoneNumberRef.current.value);
            if(checkTest){
                const userData = {
                    "phonenumber": phoneNumberRef.current.value
                  }        
                  
               toast.success("Handle forgot")
            }else {
                toast.error("Email not valid");
            }
    }

  return (
    <div className="logo-base flexlog">
      <div className="log-start">
          
          <div className="main-login-data">
            <div className="reg-header ">
                <img src={HeaderLogo} className="logo-one" alt="The Local Choice" />         
            </div>
              <div className="form-card ">
                  <div className="frm-log-area">
                      <h4 className="title-login text-center">Forgot Password</h4>
                          <form encType="multipart/form-data">
                              <div className="form-group frg">
                                  <input type="text" className="form-control ct-content matetrial-input wide100" ref={phoneNumberRef} maxLength={10} placeholder="Enter Phone Number" required/>
                              </div>
                              <div className="form-group mgtop20">
                                  <button className="btn btn-mevent btn-full" onClick={handleForgotPassword} disabled={isLoading}>Submit</button>
                              </div>
                              <div className=" frg">
                              </div>
                          </form>                         
                          
                          <p className="mgtop20 space-flex txts12">
                              <Link to="/login"  className="link-log-text">Login?</Link>
                              <Link to="/register"  className="link-log-text">Register?</Link>
                          </p>
                          <p className="text-center smal-g">
                          { CONSTANTS.VERSION}
                          </p>
                          <div className="dot dot1" ></div>
                          <div className="dot dot2" ></div>
                  </div>
              </div>
          
          </div>
      </div>       
  </div>
  )
}

export default AuthForgotScreen