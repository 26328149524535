//import logo from './logo.svg';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AuthScreens from "./Pages/AuthScreens";
import AuthLoginScreen from "./Pages/AuthLoginScreen";
import AuthRegisterScreen from "./Pages/AuthRegisterScreen";
import HomeScreen from "./Pages/HomeScreen";
import Settings from "./Pages/Settings";
import EventAdd from "./Pages/EventAdd";
import AuthForgotScreen from "./Pages/AuthForgotScreen";
import Competitions from "./Pages/Competitions";
import QRCode from "./Pages/QRCode";
import QRCodeScan from "./Pages/QRCodeScan";
import QRCodeInput from "./Pages/QRCodeInput";
import EventDetails from "./Pages/EventDetails";
import ProfileScreen from "./Pages/ProfileScreen";

function App() {
  
  return (
    <>
    <Router>
      <div className="main-outer-container">
        <Routes>
          <Route path="/login" element={<AuthLoginScreen />}/> 
          <Route path="/register" element={<AuthRegisterScreen />}/>
          <Route path="/forgot-password" element={<AuthForgotScreen />}/>  
          <Route element={<AuthScreens />}>                       
                <Route path="/" element={<HomeScreen/>}/> 
                <Route path="/add-event" element={<EventAdd />}/> 
                <Route path="/event-single/:id" element={<EventDetails />} />
                <Route path="/competitions" element={<Competitions />} />
                <Route path="/qrcode" element={<QRCode />}/> 
                <Route path="/qrcode-scan" element={<QRCodeScan />}/> 
                <Route path="/qrcode-input" element={<QRCodeInput />}/> 
                <Route path="/profile" element={<ProfileScreen />}/> 
                <Route path="/settings" element={<Settings />}/> 
            </Route>
        </Routes>
      </div>
    </Router>
    <ToastContainer />
  </>
  );
}

export default App;
