import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as CONSTANTS from "./../CONSTANTS";
import { toast } from 'react-toastify';
import axios from 'axios';
import { FaAngleLeft, FaCheckCircle, FaHourglassEnd, FaHourglassStart } from 'react-icons/fa';

function EventDetails() {
    const {user}                                                    = useSelector((state) => state.auth);

    const [activeDay, setActiveDay]                                 = useState(1);
    const params                                                    = useParams();

    const navigate                                                  = useNavigate();
    const [eventItem, setEventItem]                                 = useState(null);

    const [eventCode, setEventCode]                                 = useState("");

    const [listCurrentAgendas, setListCurrentAgendas]               = useState(null);

    const [loadAgenda, setLoadAgenda]                               = useState(false);

    const currentTime                                               = new Date(); // Current time

    useEffect(() => {
      getMyEvent();
    },[])

    useEffect(() => {
        if(eventCode.length === 5){
            collectAggendas();
        }
    },[eventCode])

    const getMyEvent = async () => {
        try{
            const res = await axios.get(CONSTANTS.API_URL +"events/details/"+ params.id, {
                headers: {
                    token: "Bearer "+ user.accessToken
                }
            });

            console.log("event");
            console.log(res);

            setEventItem(res.data);
            setEventCode(res.data.eventCode);
        }catch(error){
            console.log(error);
        }
    }

    const collectAggendas = async () => {
        try{
         setLoadAgenda(true);
         let config = {
           method: 'put',
           maxBodyLength: Infinity,
           url: CONSTANTS.API_URL +"agendas/event/" + eventCode,
           headers: { 
             token: "Bearer "+ user.accessToken
           }
         };
   
         const agRep = await axios.request(config);
         //Agenda List
         console.log("Agenda List");
         console.log(agRep.data);

           setLoadAgenda(false);
           setListCurrentAgendas(agRep.data);
        }catch(error){
         console.log(error);
         setLoadAgenda(false);
        }
     }
  return (
    <div>
        <div className="single-display">
            <div className="md-layer-cover">
                <div className="image-cover" 
                        style={
                            eventItem &&
                            eventItem.imageUrl && 
                            eventItem.imageUrl.length > 5
                              ? { backgroundImage: `url(${eventItem.imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }
                              : {}
                          }>
                    
                    <button className="btn-position-return" onClick={() => navigate(-1)}>
                       <FaAngleLeft />
                    </button>
                </div>
            </div>
            <div className="outer-block">
                
                    {
                        eventItem && (
                            <div className="single-out">
                                <div className="lbl-view">EVENT</div>
                                <h2>{eventItem.title}</h2>
                                <div className="detail-expression">
                                    <div className="date-diplay-eve">
                                        <span className="icon-blocka">
                                          <FaHourglassStart />
                                        </span>
                                        <span>
                                        {
                                            new Date(eventItem.eventDate).toLocaleDateString(undefined, {
                                                    year: 'numeric',
                                                    month: 'long',
                                                    day: 'numeric',
                                                })
                                        }
                                        </span>
                                        <span> {eventItem.eventTime}</span> 
                                    </div>
                                    <div className="date-diplay-eve">
                                        <span className="icon-blocka">
                                          <FaHourglassEnd />
                                        </span>
                                        <span>
                                        {
                                            new Date(eventItem.endDate).toLocaleDateString(undefined, {
                                                    year: 'numeric',
                                                    month: 'long',
                                                    day: 'numeric',
                                                })
                                        }
                                        </span>
                                        
                                    </div>
                                    <div className="eve-descr">
                                        About Event
                                    </div>
                                    <div className="descr-diplay-eve">
                                            {eventItem.description}
                                    </div>
                                    <div className="eve-descr">
                                       Agendas
                                    </div>
                                    <div className="agenda-listing-eve">
                                    {
                                            listCurrentAgendas && (
                                                <div className="row-agenda">
                                                {/* Horizontal Buttons */}
                                                <div className="agenda-buttons">
                                                    {listCurrentAgendas.map((agenda) => (
                                                    <button
                                                        key={agenda.day}
                                                        className={activeDay === agenda.day ? "lay-item active" : "lay-item"}
                                                        onClick={() => setActiveDay(agenda.day)}
                                                    >
                                                        Day {agenda.day}
                                                    </button>
                                                    ))}
                                                </div>

                                                    {/* Agenda List */}
                                                    <div className="agenda-content">
                                                    {
                                                    listCurrentAgendas
                                                        .find((agenda) => agenda.day === activeDay)
                                                        ?.info.map((item, index) => {
                                                            const startTime = new Date(item.startTime);
                                                            const endTime = new Date(item.endTime);

                                                                let statusClass = "";
                                                                let iconClass = "";

                                                                if (currentTime > endTime) {
                                                                    statusClass = "crossed-agenda"; // Line-through effect
                                                                    iconClass = "passed-agenda"; // Passed event
                                                                } else if (currentTime < startTime) {
                                                                    statusClass = "prior-view"; // Future event
                                                                }

                                                                return (
                                                                <div key={index} className="agenda-item ag-gray">
                                                                    <div className={`content-setup ${statusClass}`}>
                                                                    <div className="agenda-name">{item.title}</div>
                                                                        <div className={`icon-view ${iconClass}`}>
                                                                            <FaCheckCircle />
                                                                        </div>
                                                                    </div>
                                                                    <div className="date-showcase">
                                                                        <span> {item.startTime}</span> - 
                                                                        <span> {item.endTime}</span>
                                                                    </div>
                                                                </div>
                                                                );
                                                            })
                                                    }
                                                </div>
                                            </div>
                                            )
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    
                
            </div>
        </div>        
    </div>
  )
}

export default EventDetails